import { useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { SwiperNavButtons } from "../blocks/SwiperNavButtons";

export const Timeline = ({ data }) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  return (
    <section
      id="koh-i-noor-tortenet"
      className="timeline-section overflow-hidden py-32 lg:py-36"
    >
      <div className="swiper-container mb-6 lg:mb-4">
        <Swiper
          className="!overflow-visible border-t border-skafar-dark-brown "
          modules={[Parallax, Pagination, Navigation]}
          spaceBetween={30}
          loop={false}
          slidesPerView={1}
          centeredSlides={true}
          pagination={false}
          speed={1000}
          watchSlidesProgress={true}
          parallax={true}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween: 100,
            },
          }}
          onInit={(swiper) => {
            setIsBeginning(swiper.isBeginning);
          }}
          onProgress={(swiper) => {
            for (let i = 0; i < swiper.slides.length; i++) {
              const slideNode = swiper.slides[i];
              const slideProgress = slideNode.progress;
              const absProgress =
                Math.round(Math.abs(slideProgress) * 100) / 100;

              slideNode.querySelector(".slide-img").style.opacity =
                1 - absProgress;

              slideNode.querySelector(".slide-description").style.opacity =
                1 - absProgress;
            }
          }}
          onSlideChange={(swiper) => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          onSetTransition={(swiper, duration) => {
            for (let i = 0; i < swiper.slides.length; i += 1) {
              const slideEl = swiper.slides[i];
              slideEl.style.transitionDuration = `${duration}ms`;
            }
          }}
        >
          {data.slides.map((slide, index) => (
            <SwiperSlide key={index}>
              {({ isActive, isPrev, isNext }) => (
                <div className="relative slider-item flex flex-col justify-center items-center pt-16 gap-6 before:content-none ">
                  <span className="text-skafar-blue font-serif normal-case text-md lg:text-xl">
                    {slide.year}
                  </span>
                  <img
                    className="slide-img transition-all duration-500 ease-in-out"
                    src={`/${slide.imgURL}`}
                    alt={slide.year}
                  />
                  <p className="slide-description normal-case text-sm lg:text-base !leading-[24px] max-w-96 transition-all duration-500 ease-in-out px-6">
                    {slide.description}
                  </p>
                </div>
              )}
              {/* <span>isBeginning: {isBeginning}</span> */}
            </SwiperSlide>
          ))}

          <SwiperNavButtons
            hideOnMobile={false}
            padding={"small"}
            isBeginning={isBeginning}
            isEnd={isEnd}
          />
        </Swiper>
      </div>
    </section>
  );
};
