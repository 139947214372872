export const ImageTextBanner = ({ data }) => {
  return (
    <section
      id={data.id}
      className="image-text-banner grid md:grid-cols-2 gap-[1px] bg-skafar-dark-brown border-t last-of-type:border-b border-skafar-dark-brown"
    >
      <div
        className={`img-container bg-skafar-light-brown p-4 ${
          data.imgSide === "right" ? "md:col-start-2" : null
        }`}
      >
        <img className="w-full" src={data.imgURL} alt={data.title} />
      </div>

      <div
        className={`${
          data.imgSide === "right" ? "md:col-start-1 md:row-start-1" : ""
        } text-container bg-skafar-light-brown flex flex-col justify-center items-center lg:p-[100px] px-4 py-10`}
      >
        <div className="text-left flex flex-col gap-4">
          <p className="text-sm lg:text-base opacity-60">{data.subtitle}</p>
          <h3 className="text-md lg:text-lg">{data.title}</h3>
          <p className="text-sm lg:text-base normal-case max-w-[450px]">
            {data.text}
          </p>

          <a
            className="text-skafar-light-brown bg-skafar-dark-brown px-6 py-3 inline-block mt-4 w-fit transition-colors hover:bg-skafar-red"
            href={data.buttonURL}
          >
            {data.buttonText}
          </a>
        </div>
      </div>
    </section>
  );
};
