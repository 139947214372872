export const Quote = ({ data }) => {
  return (
    <section className="quote-section py-20 lg:py-40 px-4 lg:px-10  border-t  border-skafar-dark-brown ">
      <h3 className="text-2xl lg:text-3xl mb-3 lg:mb-2 font-serif normal-case max-w-[800px] mx-auto ">
        {data.quote}
      </h3>
      <p className="text-sm lg:text-base">{data.person}</p>
    </section>
  );
};
