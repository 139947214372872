import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as MenuIcon } from "../assets/menu-icon.svg";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";
import { Link } from "react-router-dom";

import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export const Header = ({ data }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(
        window.scrollY >
          document?.querySelector(".hero-section").clientHeight - 120
      );
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  const [isOpen, setIsOpen] = useState(false);

  const mobileNavRef = useRef();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useGSAP(() => {
    gsap.to(mobileNavRef.current, {
      height: isOpen ? "auto" : 0,
      ease: "power2.out",
      duration: 0.3,
    });
  }, [isOpen]);

  return (
    <header
      style={{
        background: `${
          isScrolled
            ? "#F5EEDF"
            : isOpen
            ? "#F5EEDF"
            : "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)"
        }`,
      }}
      className={` fixed lg:flex grid grid-cols-2 items-center justify-between w-full px-3 md:px-8  lg:px-[60px] z-30 py-5 text-sm lg:text-base uppercase border-skafar-dark-brown transition-all ${
        isScrolled
          ? "text-fruno-main border-b"
          : isOpen
          ? "text-fruno-main border-b"
          : "text-white"
      }`}
    >
      <nav className="left hidden lg:flex flex-1 justify-between items-center">
        {data.leftLinks.map((link, index) => (
          <a
            key={index}
            className="transition-colors hover:text-skafar-red"
            href={link.url}
          >
            {link.text}
          </a>
        ))}
      </nav>

      <div className="flex-1 ">
        <Logo
          onClick={() => {
            window.scrollTo({ top: 0 });
            setIsOpen(false);
          }}
          className={`${
            isScrolled
              ? "opacity-100"
              : isOpen
              ? "opacity-100"
              : "opacity-0 pointer-events-none"
          } w-24 lg:w-32 lg:mx-auto  cursor-pointer h-auto transition-opacity`}
        />
      </div>
      <button
        onClick={() => {
          handleClick();
        }}
        className="ml-auto icon-container w-7 h-7 lg:hidden relative"
        aria-label={`Menü ${isOpen ? "bezárása" : "kinyitása"}`}
      >
        <MenuIcon
          fill={isScrolled ? "#948079" : isOpen ? "#948079" : "white"}
          className={`${isOpen ? "opacity-0" : ""} transition-opacity`}
        ></MenuIcon>
        <CloseIcon
          className={`${
            isOpen ? "" : "opacity-0"
          } absolute w-7 h-7 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition-opacity`}
          fill={isScrolled ? "#948079" : isOpen ? "#948079" : "white"}
        ></CloseIcon>
      </button>

      <nav className="right hidden left lg:flex flex-1 justify-between items-center">
        {data.rightLinks.map((link, index) => (
          <a
            key={index}
            className="transition-colors hover:text-skafar-red"
            href={link.url}
          >
            {link.text}
          </a>
        ))}

        <div className="language-selector">
          <Link
            onClick={() => {
              window.scrollTo({ top: 0 });
              setIsOpen(false);
            }}
            to="/en"
            className={`${
              data.currentLang === "HU" ? "opacity-50" : ""
            } hover:text-skafar-red`}
          >
            EN
          </Link>
          <span>/</span>
          <Link
            onClick={() => {
              window.scrollTo({ top: 0 });
              setIsOpen(false);
            }}
            to="/"
            className={`${
              data.currentLang === "EN" ? "opacity-50" : ""
            } hover:text-skafar-red`}
          >
            HU
          </Link>
        </div>
      </nav>

      <nav ref={mobileNavRef} className="mobile h-0 overflow-hidden lg:hidden">
        <div className="pt-5 flex flex-col justify-start text-left">
          {data.leftLinks.map((link, index) => (
            <a
              onClick={() => {
                setIsOpen(false);
              }}
              key={index}
              className="transition-colors "
              href={link.url}
            >
              {link.text}
            </a>
          ))}
          {data.rightLinks.map((link, index) => (
            <a
              onClick={() => {
                setIsOpen(false);
              }}
              key={index}
              className="transition-colors"
              href={link.url}
            >
              {link.text}
            </a>
          ))}

          <div className="language-selector">
            <Link
              onClick={() => {
                window.scrollTo({ top: 0 });
                setIsOpen(false);
              }}
              to="/en"
              className={`${
                data.currentLang === "HU" ? "opacity-50" : ""
              } hover:opacity-100`}
            >
              EN
            </Link>
            <span>/</span>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0 });
                setIsOpen(false);
              }}
              to="/"
              className={`${
                data.currentLang === "EN" ? "opacity-50" : ""
              } hover:opacity-100`}
            >
              HU
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

/*  */
