import {
  Header,
  Hero,
  Products,
  ImageTextBanner,
  Quote,
  Timeline,
  Footer,
} from "../components";

const currentYear = new Date().getFullYear();

const data = {
  header: {
    currentLang: "HU",
    leftLinks: [
      {
        text: "Boraink",
        url: "#boraink",
      },
      {
        text: "A Koh-i-noor-tortenet",
        url: "#koh-i-noor-tortenet",
      },
    ],
    rightLinks: [
      {
        text: "Skáfár borklub",
        url: "#skafar-borklub",
      },
      {
        text: "Kapcsolat",
        url: "#kapcsolat",
      },
    ],
  },
  hero: {
    title: "Üdvözöljük honlapunkon!",
    text: "Ismerje meg borainkat",
  },
  products: {
    title: "KOH-I-NOOR KOLLEKCIÓ",
    subtitle: "Boraink",
    buttonText: "Előrendelés",
    products: [
      {
        imgURL: "/VILLANYI_CUVEE.jpg",
        label: "Klubtagoknak -10%",
        title: "Villányi Cuvée",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
      {
        imgURL: "/VILLANYI_CABERNET_FRANC.jpg",
        label: "Klubtagoknak -10%",
        title: "Villányi Cabernet Franc",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
      {
        imgURL: "/VILLANYI_CABERNET_SAUVIGNON.jpg",
        label: "Klubtagoknak -10%",
        title: "Villányi Cabernet Suvignon",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
      {
        imgURL: "/VILLANYI_MERLOT.jpg",
        label: "Klubtagoknak -10%",
        title: "Villányi Merlot",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
    ],
  },
  timeline: {
    slides: [
      {
        year: "Kr.e. 3200",
        imgURL: "kre3200.png",
        description:
          "Néhány forrás szerint az első utalások a gyémántról, amely később a Koh-i-noor néven vált ismertté, már a szanszkrit és talán még a mezopotámiai szövegekben is megjelentek, körülbelül i.e. 3200 körül.",
      },
      {
        year: "1352",
        imgURL: "1352.png",
        description:
          "Néhány szakértő szerint Szultán ʿAlā-ud-Dīn Khaljī 1304-ben vette el az ékszert a Malwa indiai rádzsájától, akinek családja sok generáció óta birtokolta azt.",
      },
      {
        year: "1526",
        imgURL: "1526.png",
        description:
          "Néhány író összekapcsolta a Koh-i-noort azzal a gyémánttal, amelyet Bábur fia, az indiai mogul dinasztia alapítója kapott a Gválior rádzsájától a 1526-os panipati csata után.",
      },
      {
        year: "1658",
        imgURL: "1658.png",
        description:
          "Mások szerint a gyémánt eredetileg a Krishna folyó Kollur bányájából származik, és 1656-ban adták át a mogul császárnak, Sahdzsánnak.",
      },
      {
        year: "1665",
        imgURL: "1665.png",
        description:
          "Néhányan azt állítják, hogy a követ a Nagy Mogul gyémántból vágták ki, amelyet Jean-Baptiste Tavernier, a francia ékszerkereskedő, 1665-ben írt le.",
      },
      {
        year: "1739",
        imgURL: "1739.png",
        description:
          "Valószínűleg részét képezte Nader sah iráni fosztogatásának, amikor 1739-ben Delhit megtámadta. Halála után a tábornokához, Ahmad Sahhoz, az afgán Durrani dinasztia alapítójához került.",
      },
      {
        year: "1750",
        imgURL: "1750.png",
        description:
          "Ahmad Sah leszármazottja, Shah Shojāʿ, miközben Indiában tartózkodott menekültként kénytelen volt átadni a követ Ranjit Singhnek, a szikh uralkodónak.",
      },
      {
        year: "1849",
        imgURL: "1849.png",
        description:
          "A Pandzsáb annektálásakor 1849-ben a Koh-i-noort a brit hatalom megszerezte, és Viktória királynő koronagyöngyei közé került.",
      },
    ],
  },
  wineclub: {
    name: "Image text banner",
    imgURL: "/borklub.png",
    title: "MEGNYÍLT A TAGFELVÉTEL BORKLUBUNKBA",
    subtitle: "SKÁFÁR BORKLUB",
    text: "Borklubunk eddig kizárólag meghívásos alapon fogadott új tagokat, azonban a nagy érdeklődés hatására úgy döntöttünk, a jelentkezést bárki számára elérhetővé tesszük.",
    buttonText: "Jelentkezés",
    buttonURL: "mailto:borklub@skafar.hu",
    imgSide: "right",
    id: "skafar-borklub",
  },
  contact: {
    name: "Image text banner",
    imgURL: "/kapcsolat.png",
    title: "VEGYE FEL VELÜNK A KAPCSOLATOT!",
    subtitle: "Kapcsolat",
    text: "Kérdése van borászatunkkal vagy borainkkal kapcsolatban? Forduljon hozzánk bizalommal.",
    buttonText: "Kapcsolatfelvétel",
    buttonURL: "mailto:borklub@skafar.hu",
    imgSide: "left",
    id: "kapcsolat",
  },
  quote: {
    quote: "“A bor vízben oldott napfény.”",
    person: "GALILEO GALILEI",
  },
  footer: {
    primaryNav: {
      label: "Navigáció",
      links: [
        {
          text: "Boraink",
          url: "#boraink",
        },
        {
          text: "A koh-i-noor történet",
          url: "#koh-i-noor-tortenet",
        },
        {
          text: "Skáfár Borklub",
          url: "#skafar-borklub",
        },
        {
          text: "Kapcsolat",
          url: "#kapcsolat",
        },
      ],
    },
    secondaryNav: {
      label: "Kapcsolat",
      links: [
        {
          text: "borklub@skafar.hu",
          url: "mailto:borklub@skafar.hu",
        },
        {
          text: "+36 (70) 530 80 10",
          url: "tel:+36705308010",
        },
      ],
    },
    copyrightText: `©${currentYear} all rights reserved`,
  },
};

export const Home = () => {
  return (
    <main>
      <Header data={data.header}></Header>
      <Hero data={data.hero}></Hero>
      <Products data={data.products}></Products>
      <Timeline data={data.timeline}></Timeline>
      <ImageTextBanner data={data.wineclub}></ImageTextBanner>
      <ImageTextBanner data={data.contact}></ImageTextBanner>
      <Quote data={data.quote}></Quote>
      <Footer data={data.footer}></Footer>
    </main>
  );
};
