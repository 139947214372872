import {
  Header,
  Hero,
  Products,
  ImageTextBanner,
  Quote,
  Timeline,
  Footer,
} from "../components";

const currentYear = new Date().getFullYear();

const data = {
  header: {
    currentLang: "EN",
    leftLinks: [
      {
        text: "Our wines",
        url: "#boraink",
      },
      {
        text: "The koh-i-noor-story",
        url: "#koh-i-noor-tortenet",
      },
    ],
    rightLinks: [
      {
        text: "Skáfár wine club",
        url: "#skafar-borklub",
      },
      {
        text: "Contact",
        url: "#kapcsolat",
      },
    ],
  },
  hero: {
    title: "Welcome to our website!",
    text: "Explore our wines",
  },
  products: {
    title: "Koh-I-noor Collection",
    subtitle: "Our wines",
    buttonText: "Preorder",
    products: [
      {
        imgURL: "/VILLANYI_CUVEE.jpg",
        label: "-10% for club members",
        title: "Villányi Cuvée",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
      {
        imgURL: "/VILLANYI_CABERNET_FRANC.jpg",
        label: "-10% for club members",
        title: "Villányi Cabernet Franc",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
      {
        imgURL: "/VILLANYI_CABERNET_SAUVIGNON.jpg",
        label: "-10% for club members",
        title: "Villányi Cabernet Suvignon",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
      {
        imgURL: "/VILLANYI_MERLOT.jpg",
        label: "-10% for club members",
        title: "Villányi Merlot",
        year: "2018 • 2019 • 2020",
        price: "29.990 FT",
      },
    ],
  },
  timeline: {
    slides: [
      {
        year: "3200 B.C.",
        imgURL: "kre3200.png",
        description:
          "Some sources note that the first references to the diamond, which later became known as the Koh-i-noor, appeared in Sanskrit and possibly even Mesopotamian texts as early as 3200 BCE.",
      },
      {
        year: "1352",
        imgURL: "1352.png",
        description:
          "Some experts believe that Sultan ʿAlā -ud-Dīn Khaljī took the jewel in 1304 from the raja of Malwa, India, whose family had owned it for many generations.",
      },
      {
        year: "1526",
        imgURL: "1526.png",
        description:
          "Other writers have identified the Koh-i-noor with the diamond given to the son of Bābur, the founder of the Mughal dynasty in India, by the raja of Gwalior after the battle of Panipat in 1526.",
      },
      {
        year: "1658",
        imgURL: "1658.png",
        description:
          "Others have contended that it came originally from the Kollur mine of the Krishna River and was presented to the Mughal emperor Shah Jahān in 1656.",
      },
      {
        year: "1665",
        imgURL: "1665.png",
        description:
          "Some claim that the stone was cut from the Great Mogul diamond described by the French jewel trader Jean-Baptiste Tavernier in 1665.",
      },
      {
        year: "1739",
        imgURL: "1739.png",
        description:
          "It most likely formed part of the loot of Nāder Shah of Iran when he sacked Delhi in 1739. After his death it fell into the hands of his general, Aḥmad Shah, founder of the Durrānī dynasty of Afghans.",
      },
      {
        year: "1750",
        imgURL: "1750.png",
        description:
          "Ahmad Shah's descendant'Shah Shojāʿ, when a fugitive in India, was forced to surrender the stone to Ranjit Singh, the Sikh ruler.",
      },
      {
        year: "1849",
        imgURL: "1849.png",
        description:
          "On the annexation of the Punjab in 1849, the Koh-i-noor was acquired by the British and was placed among the crown jewels of Queen Victoria.",
      },
    ],
  },
  wineclub: {
    name: "Image text banner",
    imgURL: "/borklub.png",
    title: "Entry is open to our wine club",
    subtitle: "SKÁFÁR wine club",
    text: "Our wine club has so far accepted new members exclusively by invitation; however, due to high interest, we have decided to make applications available to anyone.",
    buttonText: "Sign up",
    buttonURL: "mailto:borklub@skafar.hu",
    imgSide: "right",
    id: "skafar-borklub",
  },
  contact: {
    name: "Image text banner",
    imgURL: "/kapcsolat.png",
    title: "Get in touch with us!",
    subtitle: "Contact",
    text: "Do you have any questions regarding our winery or our wines? Feel free to reach out to us.",
    buttonText: "Get in touch",
    buttonURL: "mailto:borklub@skafar.hu",
    imgSide: "left",
    id: "kapcsolat",
  },
  quote: {
    quote: "“Wine is sunlight, held together by water.”",
    person: "GALILEO GALILEI",
  },
  footer: {
    primaryNav: {
      label: "Navigation",
      links: [
        {
          text: "Our wines",
          url: "#boraink",
        },
        {
          text: "The koh-i-noor story",
          url: "#koh-i-noor-tortenet",
        },
        {
          text: "Skáfár Wine Club",
          url: "#skafar-borklub",
        },
        {
          text: "Contact",
          url: "#kapcsolat",
        },
      ],
    },
    secondaryNav: {
      label: "Contact",
      links: [
        {
          text: "borklub@skafar.hu",
          url: "mailto:borklub@skafar.hu",
        },
        {
          text: "+36 (70) 530 80 10",
          url: "tel:+36705308010",
        },
      ],
    },
    copyrightText: `©${currentYear} all rights reserved`,
  },
};

export const English = () => {
  return (
    <main>
      <Header data={data.header}></Header>
      <Hero data={data.hero}></Hero>
      <Products data={data.products}></Products>
      <Timeline data={data.timeline}></Timeline>
      <ImageTextBanner data={data.wineclub}></ImageTextBanner>
      <ImageTextBanner data={data.contact}></ImageTextBanner>
      <Quote data={data.quote}></Quote>
      <Footer data={data.footer}></Footer>
    </main>
  );
};
