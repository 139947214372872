export const Footer = ({ data }) => {
  return (
    <footer
      id="footer"
      className="bg-skafar-red text-skafar-light-brown px-4 py-8 lg:p-0 flex flex-col gap-9 lg:gap-0"
    >
      <div className="top-container lg:p-[30px] flex flex-col gap-9 lg:flex-row items-start">
        <div className="socials-container flex gap-6 lg:gap-4 justify-center lg:justify-start w-full">
          <img
            src="/logo.svg"
            alt="skáfár logó"
            className="w-64 mx-auto lg:m-0"
          />
          {/*  <a href="/" target="_blank">
            <img
              src="/facebook-icon.svg"
              alt="Kövessen minket Facebookon"
              className=""
            />
          </a>
          <a href="/" target="_blank">
            <img
              src="/instagram-icon.svg"
              alt="Kövessen minket Instagramon"
              className=""
            />
          </a>
          <a href="/" target="_blank">
            <img
              src="/linkedin-icon.svg"
              alt="Kövessen minket LinkedInen"
              className=""
            />
          </a>
          <a href="/" target="_blank">
            <img
              src="/tiktok-icon.svg"
              alt="Kövessen minket Tiktokon"
              className=""
            />
          </a> */}
        </div>
        <div className="navigation-container flex flex-col gap-9 lg:flex-row w-full">
          <div className="left-col flex flex-col gap-6 lg:text-left w-full">
            <p className="label text-2xs lg:text-xs">{data.primaryNav.label}</p>

            {data.primaryNav.links.map((link, index) => (
              <a key={index} href={link.url} className="text-sm lg:text-base">
                {link.text}
              </a>
            ))}
          </div>

          <div className="right-col flex flex-col gap-6 lg:text-left w-full">
            <p className="label text-2xs lg:text-xs">
              {data.secondaryNav.label}
            </p>
            {data.secondaryNav.links.map((link, index) => (
              <a key={index} href={link.url} className="text-sm lg:text-base">
                {link.text}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="bottom-container flex flex-col gap-9 lg:flex-row lg:justify-between lg:items-end lg:p-[30px] lg:pt-0">
        <p className="label text-2xs lg:text-xs">{data.copyrightText}</p>
      </div>
    </footer>
  );
};
