export const Hero = ({ data }) => {
  return (
    <section className="h-screen bg-slate-400 flex flex-col justify-center items-center relative hero-section">
      <img
        src="/hero.jpeg"
        alt="Skáfár"
        className="absolute inset-0 w-full h-full object-cover"
        width="2640"
        height="1650"
        loading="lazy"
      />

      <div className="logo-container w-[300px] md:w-[512px] lg:w-auto z-50 2xl:w-[720px]">
        <img src="/logo.svg" alt="skáfár logó" className="w-full" />
      </div>

      <div className="bottom-container absolute bottom-0 text-white font-mono uppercase">
        <h3 className="text-md lg:text-lg mb-2 lg:mb-3">{data?.title}</h3>
        <p className="text-sm lg:text-base opacity-60 mb-6">{data?.text}</p>

        <div className="pulse w-[1px] h-[80px] bg-white bg-opacity-30 mx-auto relative"></div>
      </div>
    </section>
  );
};
