export const Products = ({ data }) => {
  return (
    <section id="boraink" className="products pt-32">
      <div className="header-container pb-6 text-skafar-dark-brown">
        <p className="text-sm lg:text-base mb-[6px] uppercase">
          {data.subtitle}
        </p>
        <h3 className="text-md lg:text-lg">{data?.title}</h3>
      </div>

      <div className="products-container bg-skafar-dark-brown grid md:grid-cols-2 lg:grid-cols-4 gap-[1px] border-t border-b border-solid border-skafar-dark-brown">
        {data?.products.map((product, index) => (
          <div key={index} className="bg-skafar-light-brown product-item">
            <div className="img-container relative p-4">
              <img
                className="w-full"
                src={product?.imgURL}
                alt={product?.title}
              ></img>

              <div className="label absolute top-8 left-8 text-xs text-skafar-light-brown bg-skafar-red px-3 py-2 uppercase rounded-sm">
                {product.label}
              </div>
            </div>

            <div className="text-left p-4 pt-0 text-sm lg:text-base">
              <div className="border-t border-skafar-dark-brown py-3">
                <h5>{product.title}</h5>
                <p className="tracking-[0.02em] opacity-60">{product.year}</p>
              </div>

              <p className="border-t border-skafar-dark-brown py-3">
                {product.price}
              </p>
            </div>

            <div className="button-container p-4">
              <a
                href="mailto:borklub@skafar.hu"
                className="block bg-skafar-dark-brown w-full text-skafar-light-brown p-3 transition-colors hover:bg-skafar-red"
              >
                {data.buttonText}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
