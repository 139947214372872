/* import { useState } from "react"; */
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import { Home } from "./pages/Home";
import { English } from "./pages/English";

function App() {
  return (
    <div className="App bg-skafar-light-brown">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/en" element={<English />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
